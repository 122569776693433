@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rozha+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

body {
  margin: 0;
  width: 100%;
  cursor: url("./images/cursor.svg"), default;
}

h1,
h2,
p {
  margin: 0;
  word-break: break-word !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
}

a {
  text-decoration: none !important;
}

/* Header */
.show {
  display: initial;
}

.fade {
  display: none;
}
.header {
  justify-content: space-between;
}

.header p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 27px;
  color: #000000;
  padding-top: 32px;
  padding-left: 40px;
}

.navigation {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: right;
  color: #000000;
  padding-top: 32px;
  padding-right: 40px;
}

.navigation a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: right;
  color: #000000;
  text-decoration: none;
}

.navigation select {
  border: none;
  background: transparent;
}

.open {
  display: none;
}

.close {
  display: none;
}

@media all and (max-device-width: 1400px) {
  .header {
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }

  .header a {
    width: 100%;
  }

  .header p {
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-top: 30px;
    margin: 0;
  }

  .p-active {
    background: #f8ece0;
  }

  .p-active-close {
    background: transparent;
  }

  .open {
    padding-top: 30px;
    padding-right: 20px;
    position: absolute;
    right: 0;
  }

  .open-active {
    display: initial;
  }

  .navigation-active {
    display: initial;
  }

  .navigation-close {
    display: none;
  }

  .navigation {
    background: #f8ece0;
    width: 100%;
    padding-right: 0;
    text-align: center;
  }

  .close {
    background: #f8ece0;
    border-radius: 100%;
    padding: 10px;
    position: absolute;
    bottom: 25px;
  }

  .navigation li a,
  .navigation select {
    font-family: Righteous;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    color: #f1cbb6;
  }

  .navigation li {
    padding: 30px;
  }

  .navigation li:hover {
    color: #0065f2;
  }

  .list-unstyled {
    padding-bottom: 60px;
  }
}

@media (max-device-height: 600px) {
  .close {
    bottom: 0;
  }
}

/* Fonds */
.bg-intro {
  background: url(./images/fonds/fond_intro.png);
}

.bg-project {
  background: url(./images//fonds/fond_project.png);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bg-about {
  background: url(./images/fonds/fond_about.png);
}

.bg-skills {
  background: url(./images/fonds/fond_skills.png);
}

.bg-contact {
  background: url(./images/fonds/fond_contact.png);
  overflow-x: hidden;
}

.bg-project-slides {
  position: relative;
}

.slide {
  position: absolute;
}

.container-bg {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media all and (max-device-width: 740px) {
  /* Vos règles CSS pour les mobiles ici */
  .bg-intro {
    background: url(./images/fonds/fond_mobile_intro.png);
    height: 100vh !important;
  }
}
@media all and (max-device-width: 1400px) {
  /* Vos règles CSS pour les mobiles ici */
  .bg-project {
    padding: 0 !important;
  }

  .bg-project-slides {
    background: url(./images/fonds/fond_mobile_project2.png);
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bg-about {
    background: url(./images/fonds/fond_mobile_about.png);
  }

  .bg-skills {
    background: url(./images/fonds/fond_mobile_skills.png);
  }

  .bg-contact {
    background: white;
    overflow-x: auto;
  }

  .container-bg {
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 0;
  }
}

p {
  word-break: break-all;
}
/* Intro */
.bg-intro h1 {
  position: absolute;
}

.bg-intro div {
  height: 100%;
}

.bg-intro svg {
  width: 60%;
}

.title {
  word-break: break-all;
}

#title {
  width: 60%;
  display: initial;
}

#title-mobile {
  display: none;
}

.scroll {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #000000;

  position: absolute;
  bottom: 30px;
  right: 0;
  padding-right: 200px;
}

@media all and (max-device-width: 740px) {
  #title {
    display: none;
  }

  #title-mobile {
    display: initial;
  }

  .scroll {
    display: none;
  }
}

/* Contact */

.contact-h2 {
  font-family: Rozha One;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 100%;
  color: #0065f2;
}

.contact-h3 {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #0065f2;
}

.container-contact {
  width: 730px;
  height: 390px;
  background: rgba(248, 236, 224, 0.7);
}

.gif {
  width: 240px;
  height: 276px;
  background: url(./images/giphy.gif);
  background-position: right;
  background-size: 130%;
}

.mail {
  font-family: Righteous;
  font-style: normal;
  font-weight: normal;
  font-size: 1.15rem;
  color: #0065f2;
  transform: rotate(-90deg);
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 137px;
  left: 130px;
  margin: 0 !important;
  transition: all 0.5s;
}

.mail:hover {
  height: 200px;
  bottom: 0;
  transform: rotate(0deg);
}

ul li img {
  width: 30px;
  height: auto;
  margin-top: 5px;
}

ul {
  margin-bottom: 0 !important;
}

.list-rs li {
  padding-right: 10px;
}

.list-rs li:last-child {
  padding-right: 0;
}

.container-rs {
  right: 50px;
}

form {
  width: 285px;
  margin: auto;
}

.form-floating {
  margin: 1.75em 0;
}
#mail,
#name {
  background: transparent !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 1.25px solid #0065f2 !important;
  border-radius: 0 !important;
  color: #0065f2 !important;
  padding-left: 0 !important;
  font-size: 15px !important;
  height: 30px !important;
}

textarea {
  height: auto !important;
  background: transparent !important;
  border: 1.25px solid #0065f2 !important;
  border-radius: 0 !important;
  color: #0065f2 !important;
  font-size: 15px !important;
  padding-top: 15px !important;
}

label {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  height: 100%;
  color: rgba(0, 101, 242, 1.5) !important;
  padding: 0 !important;
}

.form-floating > label {
  top: 10px !important;
  font-size: 15px !important;
}

#text-label {
  top: 3px !important;
  padding: 0.25rem 0.75rem !important;
}

textarea:hover,
#mail:hover,
#name:hover,
textarea:focus,
#mail:focus,
#name:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
  border-bottom: 1.25px solid #0065f2 !important;
}

#mail:focus ~ label,
#name:focus ~ label,
textarea:focus ~ label {
  font-size: 15px !important;
  color: #0065f2 !important;
  font-weight: 500 !important;
}

.form-checkbox {
  display: flex;
  align-items: flex-start;
  cursor: auto;
}

.form-checkbox label,
.errors,
.valid-message {
  font-weight: normal !important;
  font-size: 12px !important;
  color: #0065f2;
}

input[type="checkbox"] {
  appearance: none;
  background: transparent;
  border: 1px solid #0065f2;
  box-sizing: border-box;
  width: 30px;
  height: 12px;
  margin-right: 6px;
  cursor: pointer;
}

input[type="checkbox"]::after {
  background: #0065f2;
}

input[type="checkbox"]:checked {
  background: #0065f2;
}

.button {
  position: relative;
}

.button-send button {
  background: transparent;
  position: absolute;
  bottom: 75px;
  right: -160px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.svg-send-up {
  left: 5px;
}

#btn-arrow,
circle {
  transition: 0.3s !important;
}

.button-send svg:hover > #btn-arrow {
  fill: white;
}

.button-send svg:hover > circle {
  fill: #0065f2;
}

.button-svg {
  animation-name: spin;
  animation-duration: 9000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-svg-up {
  position: absolute;
  z-index: 999;
}
@media all and (max-device-width: 1400px) {
  .container-contact {
    width: 320px;
    height: 500px;
    margin-bottom: 50px;
    flex-direction: column;
  }

  form {
    width: 200px;
  }

  .gif {
    display: none;
  }

  .container-rs {
    right: 50px;
    bottom: 0;
  }

  .mail {
    word-break: normal !important;
    width: 100%;
    font-size: 14px;
    transform: none;
    top: 10px;
    left: -28px;
  }

  .container-rs-mob {
    position: absolute;
    top: 35px;
  }

  .button-send button {
    bottom: -133px;
    right: 38px;
    overflow-y: hidden;
  }
}

/* About */
#proverbe {
  font-weight: bold;
}

.bg-about {
  display: flex;
  align-items: center;
}
.container-about {
  position: relative;
  width: 80%;
  margin: auto;
  height: 100%;
}

.bg-about h2 {
  font-family: Righteous;
  font-size: 3.5rem;
  width: 200px;
  color: #f1cbb6;
  transform: rotate(-90deg);
  position: absolute;
  left: 390px;
  top: 135px;
}

.container-photo {
  border: 1px solid #f1cbb6;
  box-sizing: border-box;
  width: 335px;
  padding: 10px 15px;
  position: absolute;
  left: 122px;
  top: 110px;
}

.container-photo img {
  margin-bottom: 20px;
}

.container-photo p {
  font-family: Righteous;
  font-size: 1.3rem;
  line-height: 100%;
  color: #f1cbb6;
  margin: 10px 0;
}

.container-photo p:last-child {
  font-family: Lato;
  font-size: 1rem;
}

.about-text {
  background: white;
  position: absolute;
  /* width: 658px; */
  /* width: 66%; */
  left: 350px;
  bottom: 90px;
  padding: 5px 0px;
  display: flex;
  z-index: 999;
}

.about-text p {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  width: 70%;
  padding: 2px 15px;
}

.button-cv {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  color: #0065f2;
  text-decoration: none;
  border: 1px solid #0065f2;
  padding: 12px 30px;
  border-radius: 30px;
}

.arrow {
  position: absolute;
  left: 175px;
  top: 5px;
  animation: move 2s infinite;
}
@keyframes move {
  0% {
    left: 175px;
  }
  50% {
    left: 190px;
  }
  100% {
    left: 175px;
  }
}
.button-container {
  position: absolute;
  bottom: 0;
  right: 260px;
  transition: 0.3s;
}

.button-container:hover {
  margin: 0 0 10px 15px;
}

@media all and (max-device-width: 1400px) {
  .container-photo img {
    display: none;
  }

  .container-photo {
    text-align: center;
    width: auto;
    position: initial;
  }

  .bg-about h2 {
    transform: initial;
    position: initial;
    font-size: 3rem;
  }

  .about-text {
    position: initial;
    width: auto;
    display: initial;
    margin: 0;
  }

  .about-text p {
    width: 100%;
    padding: 0;
  }

  .button-container {
    position: relative;
    width: 100%;
    margin-top: 20px;
    bottom: 0;
    right: 0;
  }

  .button-cv {
    font-size: 1.4rem;
  }

  .arrow {
    position: absolute;
    top: 29%;
    left: 255px;
    width: 60px;
  }

  @keyframes move {
    0% {
      left: 255px;
    }
    50% {
      left: 265px;
    }
    100% {
      left: 255px;
    }
  }

  .bg-about {
    align-items: center;
  }
}

/* Skills */
.skills-item {
  width: 40%;
}

.skills-container {
  background: #ffffff;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.07);
}
.skills-title {
  font-family: Righteous;
  font-style: normal;
  font-weight: normal;
  font-size: 80px;
  line-height: 100%;
  color: #f1cbb6;
  width: 256px;
  width: 256px;
  word-break: break-all;
}

.skills-title-container {
  background-image: url("./images/skills.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 256px;
  height: 348px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #0065f2;
  padding: 20px;
  position: relative;
}

.skills-name {
  font-family: Rozha One;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #0065f2;
  width: 100%;
}

.skills-container ul {
  padding: 0;
  border-right: 1px solid #0065f2;
  border-left: 1px solid #0065f2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 85%;
  margin: auto;
}

.skills-container ul li {
  padding: 15px;
}

.skills-tools {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #0065f2;
  border-bottom: 1px solid #0065f2;
}

.skills-tools p {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  color: #0065f2;
  word-break: normal;
  margin: 0;
}

.skills-description,
.skills-date {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #000000;
  width: 100%;
}

.skills-name-container .echappe {
  display: initial;
  position: absolute;
  top: 0;
  right: 0;
}

.skills-name-container .skills_arrow {
  display: none;
}

.skills-container img {
  width: 10%;
}
.skills_arrow {
  width: 10%;
  height: 42px;
}

.active {
  border-bottom: 1px solid #cae0ff;
}

.active h3 {
  color: #78b0ff;
}

.active .skills_arrow {
  display: initial !important;
}

.active .echappe {
  display: none !important;
}

.onActive {
  display: none !important;
}

.active-container {
  background: transparent;
  box-shadow: none;
}

@media all and (max-device-width: 1400px) {
  .skills-flex {
    flex-direction: column !important;
  }

  .skills-item {
    width: 100%;
    margin-top: 50px;
  }

  .skills-title-container {
    background-size: 44% 100%;
    width: 100%;
    height: 119px;
    text-align: center;
  }

  .skills-title {
    font-size: 40px;
  }

  .skills-name {
    font-size: 1.5rem;
  }

  .skills-container img {
    width: 15%;
  }

  .skills-description,
  .skills-date,
  .skills-tools p {
    font-size: 0.9rem;
  }
}

/* PAGE */

.page-intro {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(248, 236, 224, 0.7);
}

.page-intro h1 {
  position: absolute;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 8rem;
  color: transparent;
  -webkit-text-stroke: 2px #0065f2;
}

.page-intro div img {
  width: auto;
  height: 100%;
}

.page-intro div {
  width: 390.14px;
  overflow-x: hidden;
  height: 492px;
  justify-content: center;
  display: flex;
}
.page-description {
  width: 80%;
  margin: 100px auto;
}

.description-container,
.capture-container,
.credit {
  margin: 100px 0;
}

.description-container {
  display: flex;
  justify-content: space-between;
}

.description-container div {
  margin-right: 30px;
  width: 100%;
}

.description-container h2 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #0065f2;
  margin: 0;
}

.description-container p {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  color: #000000;
  margin: 0;
}

.credit {
  display: flex;
}
.credit-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 50px;
}

.credit h2 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #f1cbb6;
  margin: 0;
}

.credit p {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  color: #000000;
  margin: 0;
}

.capture-container {
  position: relative;
  padding: 40px 0;
}

.capture-container h2 {
  font-family: Righteous;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 35px;
  text-align: center;
  color: #0065f2;
  margin: 20px 0;
}

.capture-container img {
  width: 90%;
}

.capture-container div {
  text-align: center;
}

.capture-border {
  width: 100%;
  height: 350px;
  border: 1px solid #0065f2;
  box-sizing: border-box;
  position: absolute;
}

.capture-top {
  top: 0;
}

.capture-bottom {
  bottom: 0;
}

.capture-img {
  position: relative;
  z-index: 1;
  margin-bottom: 45px;
}

.page-next {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-next h2 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 35px;
  color: #f1cbb6;
}

.page-next h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 5rem;
  line-height: 165px;
  color: #ffffff;
}

.page-next svg:hover > #btn-arrow-next {
  fill: white;
}

.page-next svg:hover > circle {
  fill: #f1cbb6;
}

.link-website,
.link-website a {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 35px;
  text-align: right;
  text-decoration-line: underline;
  color: #0065f2 !important;
}

@media all and (max-device-width: 1400px) {
  .description-container {
    flex-direction: column;
  }

  .description-container div {
    margin: 20px 0;
  }

  .capture-container img {
    width: 100%;
  }

  .capture-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .capture-border {
    width: 80%;
    height: 200px;
  }

  .capture-container {
    padding: 25px 0;
  }

  .capture-img {
    margin-bottom: 25px;
  }

  .page-intro h1 {
    font-size: 3rem;
    -webkit-text-stroke: 2px #0065f2;
  }

  .page-intro div {
    width: 200px;
    height: 300px;
  }
}
