.container-title {
  width: 20%;
  margin-left: 150px;
}

.h2-slider {
  width: 250px;
  font-family: Righteous;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem !important;
  line-height: 100%;
  color: #0065f2;
}

p {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 34px;
  color: #000000;
}

.slide {
  position: absolute;
}
#carousel {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}

.carousel-filter {
  display: flex;
  position: absolute;
  top: 80px;
  list-style-type: none;
  padding: 0;
}

.carousel-filter p {
  margin-right: 40px;
}

.arrow-carousel {
  z-index: 1000;
}

.arrow-right {
}

.arrow-left {
  margin-bottom: 5px;
}

.item {
  height: 100%;
  width: 100%;
  text-align: center;
  color: black;
  font-size: 40px;
  /* position: absolute; */
  /* transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s,
    background-color 1s;
  background-color: black; */
  margin-right: 30px;
}

.left-enter {
  opacity: 0;
  left: -60px;
  height: 120px;
  width: 90px;
  line-height: 120px;
  margin-top: 40px;
}

.left-enter.left-enter-active {
  opacity: 1;
  left: 50px;
  height: 150px;
  width: 110px;
  line-height: 150px;
  margin-top: 25px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.left-leave {
  opacity: 1;
  left: 650px;
  height: 150px;
  width: 110px;
  line-height: 150px;
  margin-top: 25px;
}

.left-leave.left-leave-active {
  left: 780px;
  opacity: 0;
  height: 120px;
  line-height: 120px;
  margin-top: 40px;
  width: 90px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.right-enter {
  opacity: 0;
  left: 760px;
  height: 120px;
  width: 90px;
  line-height: 120px;
  margin-top: 40px;
}

.right-enter.right-enter-active {
  left: 650px;
  opacity: 1;
  height: 150px;
  margin-top: 25px;
  line-height: 150px;
  width: 110px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.right-leave {
  left: 50px;
  height: 150px;
  opacity: 1;
  margin-top: 25px;
  line-height: 150px;
  width: 110px;
}

.right-leave.right-leave-active {
  left: -60px;
  opacity: 0;
  height: 120px;
  width: 90px;
  line-height: 120px;
  margin-top: 40px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.item ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 67px;
  height: 113px;
  border: 1px solid #0065f2;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.item-type {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #000000;
  margin-bottom: 20px;
}

.item-id {
  font-family: Righteous;
  font-style: normal;
  font-weight: normal;
  font-size: 33px;
  line-height: 25px;
  text-align: center;
  color: #0065f2;
}

.item-name {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  color: #0065f2;
}

.item-infos {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  bottom: 20px;
  margin: 0px 35px;
}

#arrow-container {
  z-index: 1000;
  padding: 125px 20px;
  position: relative;
  top: 115px;
}

.carousel-container {
  height: 100%;
  width: 65%;
  display: flex;
  align-items: center;
}

.item:hover .item-hover {
  width: 100% !important;
  height: 100% !important;
}

@media all and (max-device-width: 480px) {
  .bg-project {
    background: none;
    display: block !important;
    height: 100% !important;
  }

  .container-title {
    background: url(./images/fonds/fond_mobile_project.png);
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-left: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel-container {
    background: url(./images/fonds/fond_mobile_project2.png);
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  #arrow-container {
    display: none !important;
  }

  .sub-contain {
    width: 70%;
  }

  .item-container {
    height: 349.21px !important;
    width: 275px !important;
  }

  #carousel {
    overflow-x: scroll !important;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  #carousel::-webkit-scrollbar {
    /* WebKit */
    width: 0 !important;
    height: 0 !important;
  }
}
